import React from "react"
import styles from "./footer.module.scss"
const FooterLocation = ({ location, companyName }) => {
  const { address1, address2, city, state, zip, phone, name } = location
  const { name: stateName } = state.value[0]
  return (
    <section className={styles.location}>
      <span>
        <p className={styles.address}>
          <strong>{companyName || name.value}</strong>
          <br />
          <span>
            <span>
              {address1 && address1.value}{" "}
              {address2.value && address2.value.trim()}
            </span>
            <br />
            <span>{city && city.value}, </span>
            <span className={styles.state}>{stateName && stateName} </span>
            <span>{zip.value && zip.value}</span>
            <br />
            <span>{phone.value && phone.value}</span>
          </span>
        </p>
      </span>
    </section>
  )
}

FooterLocation.defaultProps = {
  location: {},
}

export default FooterLocation
