import React from "react"
import styles from "./footer.module.scss"
import { Link } from "gatsby"
import Credits from "./Credits"
import FooterLocation from "./FooterLocation"
import SocialIcons from "../social-icons/SocialIcons"
import { AppContext } from "../context/context"
const Footer = ({
  companyName,
  social,
  logo,
  location,
  additionalOffices,
  trackingPhone,
  footerNavItems,
  customCTA,
  isLandingPage = false,
}) => {
  const { size } = React.useContext(AppContext)
  const [brand] = logo.value
  const [office] = location.value
  if (size < 1024) {
    return (
      <footer className={styles.footer}>
        <div className="container">
          <div className={styles.footerInner}>
            <Link to="/" className={styles.logo}>
              <img
                src={brand.fluid.src}
                alt={brand.description}
                loading="lazy"
              />
            </Link>
            <div className={styles.footerRight}>
              {customCTA ? (
                <p>{customCTA}</p>
              ) : (
                <p>Request your Free Inspection</p>
              )}
              <a href={`tel:${trackingPhone}`} className={styles.phone}>
                {trackingPhone}
              </a>
              {!isLandingPage && (
                <div className={styles.socialWrapper}>
                  {social.value.map(({ id, elements }) => {
                    return <SocialIcons key={id} icons={elements} />
                  })}
                </div>
              )}
            </div>
            <div className={styles.locationWrapper}>
              <FooterLocation
                location={office.elements}
                companyName={companyName}
              />
              {additionalOffices.value.map(({ elements, id }) => {
                return <FooterLocation location={elements} key={id} />
              })}
            </div>
          </div>
          <hr />
        </div>
        <Credits footerNavItems={footerNavItems} companyName={companyName} />
      </footer>
    )
  } else {
    return (
      <footer className={styles.footer}>
        <div className="container">
          <div className={styles.footerInner}>
            <div className={styles.footerLeft}>
              <Link to="/" className={styles.logo}>
                <img
                  src={brand.fluid.src}
                  alt={brand.description}
                  loading="lazy"
                />
              </Link>
              {size > 1024 && additionalOffices.value.length <= 1 && (
                <div
                  className={styles.locationWrapper}
                  style={{
                    gridTemplateColumns: `repeat(${
                      additionalOffices.value.length + 1
                    }, 1fr)`,
                  }}
                >
                  <FooterLocation
                    location={office.elements}
                    companyName={companyName}
                  />
                  {additionalOffices.value.map(({ elements, id }) => {
                    return <FooterLocation location={elements} key={id} />
                  })}
                </div>
              )}
            </div>

            <div className={styles.footerRight}>
              {customCTA ? (
                <p>{customCTA}</p>
              ) : (
                <p>Request your Free Inspection</p>
              )}
              <a
                href={`tel:${trackingPhone}`}
                aria-label="call button"
                className={styles.phone}
              >
                {trackingPhone}
              </a>
              {!isLandingPage && (
                <div className={styles.socialWrapper}>
                  {social.value.map(({ id, elements }) => {
                    return <SocialIcons key={id} icons={elements} />
                  })}
                </div>
              )}
            </div>
          </div>
          {((size >= 768 && size < 1024) ||
            additionalOffices.value.length >= 2) && (
            <div
              className={styles.locationWrapper}
              style={
                additionalOffices.value.length <= 4
                  ? {
                      gridTemplateColumns: `repeat(${
                        additionalOffices.value.length + 1
                      }, 1fr)`,
                    }
                  : { gridTemplateColumns: "repeat(5, 1fr)" }
              }
            >
              <FooterLocation
                location={office.elements}
                companyName={companyName}
              />
              {additionalOffices.value.map(({ elements, id }) => {
                return <FooterLocation location={elements} key={id} />
              })}
            </div>
          )}
          <hr />
        </div>
        <Credits footerNavItems={footerNavItems} companyName={companyName} />
      </footer>
    )
  }
}

Footer.defaultProps = {
  social: {},
  logo: [],
  location: [],
  trackingPhone: "(207) 888-8888",
}

export default Footer
