import React from "react"
import {
  FaFacebookF,
  FaGoogle,
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaYelp,
  FaBloggerB,
  FaPinterest,
  FaVimeoV,
  FaTiktok,
} from "react-icons/fa"
import styles from "./social.module.scss"
const SocialIcons = ({ icons }) => {
  const { link, social_media_outlet } = icons

  const { codename } = social_media_outlet.value[0]

  const getSocials = codename => {
    switch (codename) {
      case "instagram":
        return (
          <a
            href={link.value}
            className={styles.socialIcon}
            aria-label="instagram button"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram />
          </a>
        )
      case "facebook":
        return (
          <a
            href={link.value}
            className={styles.socialIcon}
            aria-label="facebook button"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookF />
          </a>
        )
      case "google":
        return (
          <a
            href={link.value}
            className={styles.socialIcon}
            aria-label="google button"
            target="_blank"
            rel="noreferrer"
          >
            <FaGoogle />
          </a>
        )
      case "linked_in":
        return (
          <a
            href={link.value}
            className={styles.socialIcon}
            aria-label="linkedin button"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedinIn />
          </a>
        )
      case "twitter":
        return (
          <a
            href={link.value}
            className={styles.socialIcon}
            aria-label="twitter button"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter />
          </a>
        )
      case "yelp":
        return (
          <a
            href={link.value}
            className={styles.socialIcon}
            aria-label="yelp button"
            target="_blank"
            rel="noreferrer"
          >
            <FaYelp />
          </a>
        )
      case "youtube":
        return (
          <a
            href={link.value}
            className={styles.socialIcon}
            aria-label="youtube button"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube />
          </a>
        )
      case "blogger":
        return (
          <a
            href={link.value}
            className={styles.socialIcon}
            aria-label="blogger button"
            target="_blank"
            rel="noreferrer"
          >
            <FaBloggerB />
          </a>
        )
        case "pinterest":
          return (
            <a
              href={link.value}
              className={styles.socialIcon}
              aria-label="pinterest button"
              target="_blank"
              rel="noreferrer"
            >
              <FaPinterest />
            </a>
          )
        case "vimeo":
          return (
            <a
              href={link.value}
              className={styles.socialIcon}
              aria-label="vimeo button"
              target="_blank"
              rel="noreferrer"
            >
              <FaVimeoV />
            </a>
          )
          case "tiktok":
            return (
              <a
                href={link.value}
                className={styles.socialIcon}
                aria-label="tiktok button"
                target="_blank"
                rel="noreferrer"
              >
                <FaTiktok />
              </a>
            )
            case "nextdoor":
              return (
                <a
                  href={link.value}
                  className={styles.socialIcon}
                  aria-label="nextdoor button"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129.79 99.06" style={{width:22, height: 'auto'}} ><defs><style>{`.cls-1{fill:#fff;}`}</style></defs><g id="LG_GRN_LOGO" data-name="LG GRN LOGO"><path class="cls-1" d="M80.93,0c-17.82,0-33,8.18-41.52,20.7-.71,1-1.91,3.27-3.53,3.28-7.8.06-8.28-9.55-8.53-18.11A2.4,2.4,0,0,0,25,3.57L2.4,3.45A2.39,2.39,0,0,0,0,5.88C.5,26.82,4.35,41,29.72,46.69a3.05,3.05,0,0,1,2.36,3v47a2.36,2.36,0,0,0,2.38,2.37H56.39a2.39,2.39,0,0,0,2.39-2.38V48.12c0-10.78,7.65-23,22.15-23,13.82,0,22.16,12.25,22.16,23V96.68a2.39,2.39,0,0,0,2.39,2.38H127.4a2.39,2.39,0,0,0,2.39-2.38V44.52C129.79,19.58,108.51,0,80.93,0Z"/></g></svg>
                </a>
              )
      default:
        break
    }
  }

  return (
    <section className={styles.socialWrapper}>{getSocials(codename)}</section>
  )
}

export default SocialIcons
